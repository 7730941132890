export const cardInfoField = {
  computed: {},

  methods: {
    infoFieldChanged(field) {
      return this.$api.card.updateCardInfoField({
        card_id: this.card.id,
        name: field,
        value: this.card.info[field],
      })
        .then(data => {
          if( !data.errors.length ){
            this.$notify.success({
              title: 'Сохранено',
              message: 'Карта успешно сохранена'
            });
          } else {
            data.errors.forEach(errorText => {
              this.$notify.error({
                title: 'Ошибка',
                message: errorText
              });
            })
          }

        })
        .finally(() => {

        })
    }
  }
}